<template>
  <div class="verification-container">
    <div class="signUp-header">
      <img src="../images/logo.png" />
    </div>
    <h3 v-if="!userHasAccess">
      You have successfully created your new account. 
      <br>Email Verification has been sent to your Email. 
      <br>Please check your email and refresh this page to continue.
    </h3>
    <div v-else class="verified-block">
      <h3>Great! Your email has been verified!</h3>
      <button @click="handleClick">Let's go VioLegacy!</button>
    </div>
  </div>
  
</template>

<script>
import useSendVerification from "@/composables/Verification/useSendVerification";
import getUserAccessStatus from "@/composables/Verification/getUserAccessStatus";
import sendWelcomeEmail from "@/composables/Email/sendWelcomeEmail";
import { useRouter } from "vue-router";
import getUser from "@/composables/getUser";

export default {
  setup() {
    const { user } = getUser();
    const router = useRouter();
    const { userHasAccess } = getUserAccessStatus()
    const { USVerror, sendVerification } = useSendVerification();

    if(!userHasAccess.value){
      sendVerification()
    }

    const handleClick = () => {
      router.push({ name:"Junior-Home" })
      sendWelcomeEmail(user.value.displayName, user.value.email)
    }


    return {
      userHasAccess,
      handleClick
    }
  }
}
</script>

<style scoped>
.verification-container {
  margin: 4vh 15vw;
  margin-bottom: 8vh;
}

.verification-container h3{
  line-height: 50px;
  width: 650px;
  margin: 3vh auto;
}

.verified-block{
  display:flex;
  flex-direction: column;
  align-items: center;
}

.verified-block h3{
  text-align: center;
  vertical-align: middle;
  margin: 8vh 0 8vh 0;
}

.verified-block button{
  text-align: center;
  vertical-align: middle;
  padding: 2vh 4vh;
  color: white;
  font-size: 120%;
  font-weight: 500;
  background-color: #cca2d8;
  border-radius: 4vh;
  border: none;
}

.verified-block button:hover{
  background-color: #c069d8;
  cursor: pointer;
}

.signUp-header {
  display: flex;
  padding: 20px 0;
  padding-left: 2vw;
  align-items: center;
}

.signUp-header div {
  flex: 0 1 20%;
  margin-right: 1vw;
}

</style>