import { ref } from 'vue'
import { auth } from '../../firebase/config'

const USVerror = ref(null)

const sendVerification = async () => {
  USVerror.value = null

  try {
    await auth.currentUser.sendEmailVerification()
    alert("Email Verification Sent. Please check your email and Refresh.")
  }
  catch(err) {
    alert("Failed to Send Email Verification.")
    USVerror.value = err.message
  }
}

const useSendVerification = () => {
  return { USVerror, sendVerification }
}

export default useSendVerification