import { ref } from 'vue'
import { auth, db } from '@/firebase/config'


const sendWelcomeEmail = async (username, useremail) => {
  try {
    await db.collection("mail").add({
      to: useremail,
      template: {
        name: 'signUpNote',
        data: {
          username: username,
        }
      }
    })
    // console.log("sendWelcomeEmail activated.")
  } catch (error) {
    alert("sendWelcomeEmail: " + error)
  }

};

export default sendWelcomeEmail
